<template>
    <router-view @contextmenu.prevent />
</template>

<script setup>
import { provide, onMounted, onUnmounted, ref, watch } from "vue"
import { decrypt } from '@/utils/cryptoAES.js';
let socket
let socket1
let flag = 'close'
let flag1 = 'close'
let sto
let sto1
const init = () => {
    socket = new WebSocket('ws://xf.lcjaxf.cn:51800/webSocket')
    // socket = new WebSocket('ws://192.168.1.14:51800/webSocket')
    socket.onopen = () => {
        if (flag === 'padding') {
            location.reload()
        }
        flag = 'success'
        console.log('websocket success');
    }
    socket.onmessage = (event) => {
    }
    socket.onclose = () => {
        console.log('websocket close');
        flag = 'padding'
        if (flag === 'padding') {
            socket.close()
            sto = setTimeout(() => {
                init()
            }, 2000)
        }
        else {
            location.reload()
            return clearTimeout(sto)
        }
    }
    provide('socket', socket)
}
// const itemsId = JSON.parse(decrypt((window.localStorage.getItem('userInfo')))).itemsId
// const userId = JSON.parse(decrypt((window.localStorage.getItem('userInfo')))).id

const init1 = () => {
    const userInfo = window.localStorage.getItem('userInfo')
    const parsedUserInfo = userInfo ? JSON.parse(decrypt(userInfo)) : null
    const itemsId = parsedUserInfo ? parsedUserInfo.itemsId : null
    const userId = parsedUserInfo ? parsedUserInfo.id : null
    socket1 = new WebSocket(`ws://xf.lcjaxf.cn:51800/webSocket/${userId}/${itemsId}`)
    // socket1 = new WebSocket(`ws://192.168.1.14:51800/webSocket/${userId}/${itemsId}`)
    socket1.onopen = () => {
        if (flag1 === 'padding') {
            location.reload()
        }
        flag1 = 'success'
        console.log('websocket success');
    }
    socket1.onmessage = (event) => {
    }
    socket1.onclose = () => {
        console.log('websocket close');
        flag1 = 'padding'
        if (flag1 === 'padding') {
            socket1.close()
            sto1 = setTimeout(() => {
                init()
            }, 2000)
        }
        else {
            location.reload()
            return clearTimeout(sto1)
        }
    }
    provide('socket1', socket1)
}
 
onMounted(() => {
    init()
    window.onbeforeunload = () => {
        socket.close();
        socket1.close();
    }
    init1()
})


</script>

<style lang="scss" >
html {}

html,
body,
#app {
    height: 100px;
}

@media screen and (max-width: 768px) {

    body {

        font-size: 10px !important;

    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    body {

        font-size: 12px !important;

    }
}

@media screen and (min-width: 1024px) {

    body {

        font-size: 14px !important;

    }
}

.el-text {
    cursor: pointer;
}

.el-tag {
    cursor: pointer;
}

.el-table {
    width: 98% !important;
    margin: 10px auto;
}

.table1 {
    .custom-header {
        background-color: #cadef777 !important;
    }

    // 表体
    .el-table__inner-wrapper {
        background-color: transparent !important;
        color: #000;
        border-bottom: 0;
    }

    //    表头字体颜色
    .el-table__header-wrapper .cell {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        color: rgb(21, 32, 134);
    }
}

.table2 {
    background-color: rgba($color: #f1e1e1, $alpha: .4) !important;
    border-radius: 20px;
    box-shadow: 0 10px 20px 10px #e2e2e2;
    text-align: center !important;

    // 表体
    .el-table__inner-wrapper {
        background-color: transparent !important;
        color: #000;
        border-bottom: 0;
    }

    // 表头圆角及边框
    .el-table__header-wrapper {
        border-radius: 20px;
        border: 1px solid yellowgreen;
        box-shadow: 0 5px 5px 5px #e2e2e2;
    }

    // 表头字体颜色
    .el-table__header-wrapper .cell {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        color: rgb(33, 151, 33);
    }

    .el-table__body-wrapper {
        border-radius: 20px;
        margin-top: 10px;
    }
}

.option1 {
    width: 98% !important;
    margin: 10px auto 15px !important;
    background-color: rgba(255, 255, 255) !important;
    padding: 10px 15px !important;
    box-sizing: border-box;

}

.option2 {
    width: 98% !important;
    margin: 10px auto 0px !important;
    background-color: rgba(255, 255, 255) !important;
    border-radius: 10px !important;
    box-shadow: 0 0 10px 5px #e2e2e2;
    padding: 10px 15px !important;
    box-sizing: border-box;

}


:deep(.custom_select) {
    // width: 400px;
    height: 30px;

    :deep(.el-input__wrapper) {
        height: 32px;

    }

}


.el-input-number__decrease,
.el-input-number__increase {
    display: none !important;
}

.el-input__wrapper {
    padding: 0 15px !important;
}



/* 修改垂直滚动条 */
::-webkit-scrollbar {
    width: 8px;
    /* 修改宽度 */
}

::-webkit-scrollbar {
    height: 8px;
    /* 修改宽度 */
}

/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
}

/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
    background-color: rgb(114, 114, 114);
}

/* 修改滚动条滑块的圆角 */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
}</style>
